
import React,{useState,useEffect} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faHouse, faPhone, faMobilePhone } from '@fortawesome/free-solid-svg-icons'

import './App.css';
import logo from './logo.png';


export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<Card />} />
      </Routes>
    </Router>
  );
}


function Card() {
  let { id } = useParams();
  const [data,setData]=useState({});

  useEffect(()=>{
      function getData() {
          fetch('users.json',
          {
              headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
              }
          }
          )
          .then(function(response){
              return response.json();
          })
          .then(function(myJson) {
              setData(myJson[id])
          });
      }
      getData()
  },[])

  function saveContactDetail(){
    var text = `BEGIN:VCARD\nVERSION:4.0\nN:${data.name};\nFN:${data.name}\nORG:Payceler\nTEL;TYPE#work,voice;VALUE#uri:tel:${data.office}\nTEL;TYPE#home,voice;VALUE#uri:tel:${data.mobile}\nEMAIL:${data.email}\nADR;TYPE#HOME;LABEL#"${data.address}":;;${data.address}\nEND:VCARD`;
    var blob = new Blob([text], {type: 'text/plain'});
    const url = window.URL.createObjectURL(
        new Blob([blob]),
      )
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.download = "contact.vcf";
    link.href = url;
    link.click();
    document.body.removeChild(link); // remove the link when done

  }


  return (
      <div className="App-header">
        <div className="App-card">
          <div className="App-card-header">
              <strong>{data.name}</strong><br/>
              <span className='App-grey'>{data.position}</span>
          </div>
          <div className="App-card-body">
            <div style={{width:"70%"}}>
              <FontAwesomeIcon icon={faPhone} className="App-PrimaryColor" /> <a href={ `tel:${data.office}`}>{data.office}</a> <br />
              <FontAwesomeIcon icon={faMobilePhone} className="App-PrimaryColor" /> <a href={ `tel:${data.office}`}>{data.mobile} </a>

              <p> <FontAwesomeIcon icon={faHouse} className="App-PrimaryColor" /> {data.address}</p>

              <a href={ `https://${data.website}`}>{data.website}</a>
            </div>

            <div style={{width:"30%", textAlign:"right"}}>
              <img src={logo} className="App-logo" alt="logo" />
            </div>




          </div>
          <div className="App-card-footer App-right">
              <a href={ `mailto:${data.email}`}><FontAwesomeIcon icon={faEnvelope} className="App-PrimaryColor" /> {data.email} </a>
          </div>
        </div>

        <div className='App-saveregion'>
            <button onClick={saveContactDetail} >Save Contact Details</button>
        </div>
      </div>
  );


}

